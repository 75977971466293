// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
.login-body{
  background-image: url("./assets/img/banner.png");
  background-size: 100% 100%;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
  display: inline-flex;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
.form-inline+button.btn.btn-success {
  position: relative;
  top: -2px;
  margin: 0px 15px;
  float: right;
}
form.form-inline.search-form {
  display: inline-flex;
}
.card-footer a.btn.btn-danger.btn-sm {
  border: 1px solid #fa9594 !important;
  display: inline-block;
}
a.btn.btn-danger.btn-sm {
  -webkit-appearance: none;
  -webkit-appearance: none;
}
form.form-inline.search-form + a.btn.btn-success {
  -webkit-appearance: none;
  border: 0px;
 /* float: right;*/
  display: inline-block;
  margin-top: -4px;
  padding: 7px 15px;
  margin-left: 10px;
}
.btn{
  -webkit-appearance: none;
}
a.link-button.btn.btn-secondary{
  background: #31a4c5;
  border-radius: 0px;
  color: #fff;
  padding: 5px 0px;
}
.text-value.left_content {
  font-size: 46px;
  position: relative;
  padding-bottom: 0;
  line-height: inherit;
  top: -20px;
}
.text-value.left_content span {
  display: block;
  font-size: 16px;
  font-weight: normal;
}
span.small-box {
  position: absolute;
  right: 0px;
  top: 18px;
}
span.small-box i {
  font-size: 57px;
}
.ql-editor{
  min-height: 400px;
}
.pending_btn button.btn.btn-danger,
.pending_btn button.btn.btn-success{
  pointer-events: none;
}
.table thead tr, .table tbody tr:last-child{
  border-bottom : 1px solid #ccc;
}



#frame {
  width: 95%;
  min-width: 360px;
  max-width: 100%;
  height: 75vh;
  min-height: 300px;
  max-height: 720px;
  background: #E6EAEA;
}
@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}
#frame #sidepanel {
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 40%;
  height: 100%;
  background: #2c3e50;
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}
#frame #sidepanel #profile {
  width: 80%;
  margin: 25px auto;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #32465a;
  }
}
#frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap {
    height: 55px;
  }
}
#frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}
#frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#frame #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#frame #sidepanel #profile .wrap #status-options:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
  border: 1px solid #2ecc71;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
  border: 1px solid #f1c40f;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
  background: #e74c3c;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
  border: 1px solid #e74c3c;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
  background: #95a5a6;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
  border: 1px solid #95a5a6;
}
#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}
#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}
#frame #sidepanel #search {
  border-top: 1px solid #32465a;
  border-bottom: 1px solid #32465a;
  font-weight: 300;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}
#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}
#frame #sidepanel #search input {
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  padding: 10px 0 10px 46px;
  width: calc(100% - 25px);
  border: none;
  background: #32465a;
  color: #f5f5f5;
}
#frame #sidepanel #search input:focus {
  outline: none;
  background: #435f7a;
}
#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #contacts {
  height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}
#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
}
#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}
#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}
#frame #sidepanel #contacts ul li.contact:hover {
  background: #32465a;
}
#frame #sidepanel #contacts ul li.contact.active {
  background: #32465a;
  border-right: 5px solid #435f7a;
}
#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important;
}
#frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: -2px 0 0 -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6;
}
#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}
#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}
#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}
#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
  width: calc(100% - 50px);
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: .5;
}
#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}
#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}
#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}
#frame #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}
#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}
#frame .content {
  float: right;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame .content {
    width: calc(100% - 58px);
    min-width: 300px !important;
  }
}
@media screen and (min-width: 900px) {
  #frame .content {
    width: calc(100% - 340px);
  }
}
#frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
  height: 40px;
  object-fit: cover;
}
#frame .content .contact-profile p {
  float: left;
}
#frame .content .contact-profile .social-media {
  float: right;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame .content .messages {
  height: auto;
  min-height: calc(100% - 93px);
  max-height: calc(100% - 93px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}
#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame .content .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
  padding-bottom: 10px;
}
#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 30px;
}
#frame .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent p {
  background: #435f7a;
  color: #f5f5f5;
}
#frame .content .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies p {
  background: #f5f5f5;
  float: right;
}
#frame .content .messages ul li img {
  width: 22px;
  border-radius: 50%;
  float: left;
  height: 22px;
  object-fit: cover;
}
#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 100%;
  line-height: 130%;
}
@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 100%;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 99;
  padding: 0px 25px;

}
#frame .content .message-input .wrap {
  position: relative;
}
#frame .content .message-input .wrap input {
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  float: left;
  border: none;
  width: calc(100% - 50px);
  padding: 11px 32px 10px 8px;
  font-size: 0.8em;
  color: #32465a;
  height: 45px;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
}
#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: .5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #32465a;
  color: #f5f5f5;
  height: 45px;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}
#frame .content .message-input .wrap button:hover {
  background: #435f7a;
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}
#sidepanel #contacts ul li.contact {
  top: 15px;
}
.sent span.chat-message-outer{
  display: inline-block;
  position: relative;
  max-width: calc(100% - 180px);
}

.replies span.chat-message-outer {
  display: inline-block;
  position: relative;
  max-width: calc(100% - 180px);
  float: right;
}
.sent span.chat-message-outer span{
  font-size: 11px;
  color: #868686;
  position: absolute;
  display: inline-block;
  left: 0px;
  bottom: 0px;
  width: 120px;
}
.replies span.chat-message-outer span{
  font-size: 11px;
  color: #868686;
  position: absolute;
  display: inline-block;
  right: 0px;
  bottom: 0px;
  width: 120px;
}
#contacts ul {
  padding: 0px;
  list-style: none;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta span.unreadNoti {
  position: absolute;
  left: initial;
  right: 0px;
  top: 4px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #32465a;
  background: whitesmoke;
  font-size: 11px;
  padding: 0px;
}

/*****css by Raju*******/
.countrySelect div:last-child{
  width:86%;
}
.card.bg-primary {
    border-color: #ff8417;
}
.bg-primary {
    background-color: #ff8417 !important;
}
.btn-primary {
    color: #fff;
    background-color: #ff8417;
    border-color: #ff8417;
}
.btn-primary:hover {
    color: #fff;
    background-color: #3a2824;
    border-color: #3a2824;
}
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active, 
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3a2824;
    border-color: #3a2824;
}
.sidebar{
  background: #3a2824;
}
.sidebar .nav-link.active {
    color: #fff;
    background: #ff8417;
}
.sidebar .nav-link.active .nav-icon, .sidebar .nav-link .nav-icon {
    color: #fff;
}
.sidebar .nav-link:hover {
    color: #fff;
    background: #ff8417;
}
.sidebar .nav-dropdown-toggle::before, .sidebar .sidebar-minimizer::before{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");  
}
a.link-button.btn.btn-secondary{
    background: #3a2824;
}
a{
  color: #ff8417;
}
a:hover {
    color: #3a2824;
    text-decoration: underline;
}
.dropdown-item:hover, .dropdown-item.active, .dropdown-item:active{
  background-color: #ff8417;
  color:#fff !important;
}
.dropdown-item:hover i, .dropdown-item.active i, .dropdown-item:active i{
  color:#fff !important;
}
.dropdown-item i{
  color:#2c3e50;
}
.restaurant_odd {
    background: #f0f3f5;
    padding: 10px 15px 5px;
    border-radius: 5px;
    margin-bottom: 15px;
}
.restaurant_even {
    padding: 10px 15px 5px;
    border-radius: 5px;
    margin-bottom: 15px;
}
.restaurant_even img.card-img-top {
    width: 100%;
    height: 140px;
    object-fit: contain;
    object-position: left;
}

.product_outer {
    background: #f0f3f5;
    padding: 5px;
    border-radius: 5px;
}

.product_outer p {
    margin-bottom: 1px;
}
.input-checkbox {
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 15px;
}
.input-checkbox input[type="checkbox"] {
    position: relative;
    top: 2px;
    margin-right: 7px;
}
.orderItemTable .table-responsive {
    width: 73.5%;
}
.orderItemTable .table-responsive table.table.table-bordered {
    width: 1500px;
}
.orderItemTable .table-responsive th:first-child {
    width: 100px;
}
td.userTd {
    min-width: 160px;
}
.needs-validation>.card-body>.orderData>.table-responsive {
    overflow: hidden;
}
.needs-validation>.card-body>.orderData>.table-responsive tr td:first-child {
    font-weight:bold;
}

/********************** 17-09-2019 **********************/
body,
html {
    font-family: 'Roboto', sans-serif;
    margin: 0px;
    padding: 0px;
	background:#fff;
	font-size:16px;
}
img {
  max-width: 100%;
}
section {
    overflow: hidden;
}
section.top-header {
    background: #fff8f2;
    border-bottom: 1px solid #e7e7e7;
}
.select-right {
    text-align: right;
    display: inline-flex;
    float: right;
    margin: 10px 0px;
}
ul.language {
    list-style: none;
    margin: 0px;
    border-right: 1px solid #ccc;
    padding: 0px 6px;
    display: flex;
}
ul.language li {
    display: inline-block;
    margin: 0px 5px;
}
.massages-count {
    padding: 0px 0px 0px 10px;
    position: relative;
}
.massages-count span {
    position: absolute;
    width: 22px;
    height: 22px;
    background: #fff;
    text-align: center;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -6px;
    right: -17px;
}
header {
    box-shadow: 0px 10px 16px 0px rgba(183, 183, 183, 0.37);
    background: #fff;
    padding-bottom: 10px;
}
header nav.navbar.navbar-expand-lg.navbar-light {
    padding: 0px;
    margin-top: -40px;
}
.menus {
    width: 100%;
    text-align: right;
}
.menus>div {
    float: right;
    padding: 15px 0px 0px;
    position: relative;
    top: 20px;
}
.navbar-light .navbar-nav .nav-link::after {
	content: "";
	width: 1px;
	height: 20px;
	background: #ccc;
	position: absolute;
	right: 0px;
}
header ul li a{
	color:#000 !important;
	position: relative;
	padding: 5px 15px !important;
}
header ul li:last-child a {
    padding-right: 0px !important;
}
header ul li:last-child a.nav-link::after {
    display: none;
}
header ul li a:hover, header ul li a.active {
    color: #ff8213 !important;
}
section.restaurent-features, .order-details {
    padding: 30px 0px;
    min-height: 82vh;
}
.heading h2{
	font-size: 26px;
    color: #472823;
}
.switch { 
	width: 55px;
    height: 35px; 
	display: inline-block; 
	border-radius: 35px; 
	overflow: hidden; 
	position: relative; 
	margin:5px 0px;
}
.switch input { 
	width: 100%; 
	height: 100%; 
	opacity: 0; 
	position: absolute; 
	top: 0px; 
	left: 0px; 
	z-index: 2; 
	padding: 0px 0px; 
	margin: 0px 0px;
}
.slider { 
	position: absolute; 
	cursor: pointer; 
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0; 
	background-color: #ccc; 
	-webkit-transition: .4s; 
	transition: .4s;
}
.slider:before { 
	width: 27px; 
	height: 27px; 
	border-radius: 50%; 
	content: ""; 
	position: absolute; 
	left: 4px; 
	bottom: 4px; 
	background-color: #ffffff; 
	-webkit-transition: .5s; 
	transition: .5s; 
	text-indent: 30px; 
	font-size: 14px; 
	color: #2196F3;
}
.switch input:checked + .slider { 
	background-color: #ff8612;
}
.switch input:checked + .slider:before { 
	left: calc(100% - 31px); 
	content: ""; 
	color: #ffffff; 
	text-indent: -30px;
}
.product-outer {
    display: flex;
    margin: 24px 0px 0px;
}
.product-outer span.product-img {
    width: 100px;
}
.product-outer span.product-content {
    width: calc(100% - 100px);
    padding-left: 15px;
}
.product-outer span.product-content h3 {
    font-size: 22px;
    margin-bottom: 3px;
}
.product-outer span.product-content p {
    margin-bottom: 4px;
}
span.pause-off {
    position: relative;
    left: 9px;
    top: -15px;
}
.box-outer {
    border: 1px solid #efefef;
    display: inline-block;
    width: 100%;
    margin-top: 15px;
}
ul.box-list {
    padding: 0px;
    list-style: none;
    margin: 0px;
}
ul.box-list li {
    width: calc(100% / 5);
    float: left;
    text-align: center;
    padding: 35px 35px 20px	;
    min-height: 170px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}
ul.box-list li a span {
    padding: 15px;
    box-shadow: 0px 0px 39.06px 2.94px rgba(178, 178, 178, 0.28);
    border-radius: 10px;
    height: 120px;
    width: 130px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
ul.box-list li a{
	text-decoration: none;
}
ul.box-list li a h5 {
    margin: 10px 0px 0px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
}
footer {
    background: #000;
    text-align: center;
}
footer p {
    color: #fff;
    margin: 0px;
    padding: 15px 0px;
}
/********************** 17-09-2019 **********************/


.app, app-dashboard, app-root{
	display:initial;
}
.app.login-body{
  display:flex;
}

/***************** 30 sep 2019 ********************/
ul.box-list li a:hover h5 {
  color: #ff8213;
}
.order-details .table {
  background-color: #f9f9f9;
}
.order-details .table thead th{
  border: 0px;
}
.order-details .table td{
  border-top: 2px solid #ffffff;
}
.print-rejet-btn .btn {
  width: calc(100% / 2 - 2px);
  box-shadow: none;
  outline: none;
  color: #fff;
  border-radius: 0px;
  margin: 1px;
}
.print-rejet-btn .rejet-btn {
  background: #472823;
}
.print-rejet-btn .print-btn {
  background: #ff8213;
}
.print-rejet-btn .print-btn:hover {
  background: #e4720c;
}
.print-rejet-btn .rejet-btn:hover {
  background: #401f1a;
}
span.price {display: inline-block;width: 100px;float: right;}
.search-form-outer {
  width: 100%;
}
.search-form-outer>div {
  display: inherit;
}
main{
  padding:25px 0px;
  min-height: calc(100vh - 168px);  
}
.react-datepicker-wrapper {
  display: block !important;
  padding: 0;
  border: 0;
}
.restaurant_even img.restr-logo.card-img-top {
  height: initial;
}
ul.box-list li a span p.card-count {
  position: absolute;
  right: -10px;
  top: -6px;
  background: #ff8117;
  color: #fff;
  font-size: 12px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

ul.box-list li a span p.lcard-count {
  position: absolute;
  left: -10px;
  top: -6px;
  background: #ff8117;
  color: #fff;
  font-size: 12px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}


.delete-img {
  position: relative;
}
.delete-img span {
  position: absolute;
  right: -9px;
  top: -9px;
  background: #ff0000;
  color: #fff;
  font-size: 12px;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.orderStatus button {
  width: calc(100% / 5 - 5px);
  border-radius: 0px;
  margin-right: 1px;
  box-shadow: none !important;
}
.zoneData {
    padding: 5px 5px 6px 6px;
    background: #3a2824;
    border-radius: 5px;
    margin: 3px 5px 3px 0;
    display: inline-block;
    font-size: 12px;
    color: #fff;
}
/*****17/04/2020*****/
.menus {
  text-align: right;
  display: flex;
  float: right;
  align-items: center;
  justify-content: flex-end;
}
a.navbar-brand {
  padding-left: 8%;
}
.menus .navbar-collapse {
  flex-basis: auto;
  flex-grow: inherit;
  align-items: center;
}
header ul li a{
  text-align:center;
}
.forgotPP {
    position: relative;
}

.forgotPP #google_translate_element {
    position: absolute;
    right: 0px;
    top: 0px;
}
@media(max-width: 1300px){
  div#google_translate_element {
    position: absolute;
    top: -4px;
    right: 20px;
}
}
@media(max-width:991.98px){
  button.navbar-toggler {
      float: right;
      position: absolute;
      right: 0px;
  }
  .menus {
      width: auto;
  }
  div#google_translate_element {
    margin-right: 54px;
    margin-top: 0px;
    top: 28px;
  }
}
@media(max-width: 575.98px){
  div#google_translate_element {
    margin-right: 0;
    margin-top: 0px;
    top: -12px;
    right: 0px;
  }
}